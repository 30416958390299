<template>
  <v-list-item
    v-for="(item, index) in charts_disp"
    :key="item.pair"
  >
    <v-list-item-title>
      {{item.pair}}
      <span :class="`ma-3 text-${item.value_color}`">
        {{ getValueF(item.close, item.digits) }}
      </span>
      <span :class="`ma-3 text-${item.value_color} ml-n3 text-h5`">
        {{ getValueM(item.close, item.digits) }}
      </span>
      <span :class="`ma-3 text-${item.value_color} ml-n3`">
        {{ getValueB(item.close, item.digits) }}
      </span>
    </v-list-item-title>
    <v-list-item-subtitle>{{item.update_time}}</v-list-item-subtitle>
    <template v-slot:append>
      
      <v-progress-circular v-if=item.is_update  indeterminate color="primary"></v-progress-circular>
      <v-btn
        color="grey-lighten-1"
        icon="mdi-eye-off"
        variant="text"
        @click="hidden(item.pair)"
      ></v-btn>
      <v-btn
        v-if=!item.is_update
        color="grey-lighten-1"
        icon="mdi-alarm"
        variant="text"
        :to="{ path: 'alert', query: {pair: item.pair} }"
      ></v-btn>
      <v-btn
        v-if=!item.is_update
        color="grey-lighten-1"
        icon="mdi-reload"
        variant="text"
        @click="reload(index, item.pair)"
      ></v-btn>
      <v-btn
        color="grey-lighten-1"
        icon="mdi-chevron-right"
        variant="text"
        target="_blank"
        :to="{ path: 'imageview', query: {pair: item.pair} }"
      ></v-btn>
    </template>
  </v-list-item>
  <v-row class="justify-space-between">
    <v-col class="text-right">
      <v-btn style="margin: 10px;" color="primary" prepend-icon="mdi-checkbox-multiple-blank-outline" @click="allopen()">すべて開く</v-btn>
      <v-btn style="margin: 10px;" color="primary" prepend-icon="mdi-reload" @click="allreload()">一括更新</v-btn>
    </v-col>
  </v-row>

</template>

<script>
  import axios from 'axios'

  export default {
    data () {
      return {
        charts: [
          {
            pair: 'USDJPY',
            is_update: false,
            chart_path: "/01/07/aaaa/USDJPY.png",
            update_time: 'Sun, 07 Jan 2024 14:55:57 GMT',
            is_close_up: 0,
            value_color: "green",
          },
        ],
        charts_disp: [],
        updateTimer: 0, // clearIntervalで削除するためタイマーを宣言しておく
        hidden_pair: [],
      }
    },
    created: function () {
      this.getData();
    },
    mounted: async function() {
        // 宣言したタイマーにsetIntervalで実行する処理を代入
        this.updateTimer = setInterval(async () => {
          await this.getData()
        }, 3000)
    },
    unmounted() {
        // ページを移動した際（コンポーネントインスタンスがアンマウントされた際）にはsetIntervalを削除する
        // Vue3のライフサイクルフック unmountedでタイマーをクリアする
        clearInterval(this.updateTimer)
    },
    methods: {
      // データ取得
      async getData() {
        axios.get(process.env.VUE_APP_API_URL + '/capchart/lists')
        .then(response => {
          console.log(response.data); // mockData
          console.log(response.status); // 200
          var oldCharts = this.charts
          this.charts = response.data.charts
          this.charts.forEach(item => {
            oldCharts.forEach(itemR => {
              if (itemR.pair === item.pair) {
                if (itemR.close > item.close) {
                  item.value_color = "red"
                } else if (itemR.close < item.close) {
                  item.value_color = "blue"
                } else {
                  item.value_color = itemR.value_color
                }
              }
            })
          })
          this.charts_disp.splice(0, this.charts_disp.length) 
          this.charts.forEach(item => {
            var hiddenflg = false
            this.hidden_pair.forEach(pair => {
              if (item.pair === pair) {
                hiddenflg = true
              }
            })
            if (!hiddenflg) {
              this.charts_disp.push(item)
            }
          })
        })
      },
      getValueF(value, digits) {
        if (value != null) {
          const factor = Math.pow(10, digits-3);
          var conma = ""
          if (digits == 3) {
            conma = "."
          }
          return (Math.floor(value * factor) / factor) + conma;
        }
        return ''
      },
      getValueM(value, digits) {
        if (value != null) {
          const strNum = value.toString();
          const decimalIndex = strNum.indexOf('.');

          if (decimalIndex === -1) {
              return "00";  // 小数点がない場合は "00"
          }

          const decimalPart = strNum.substring(decimalIndex + digits - 2);
          if (decimalPart.length < decimalIndex + digits) {
              // 小数点以下の桁数が足りない場合、ゼロで埋める
              return (decimalPart + "0000").substring(0, 2);
          }

          return decimalPart.substring(0, 2);
        }
        return ''
      },
      getValueB(value, digits) {
        if (value != null) {
          const strNum = value.toString();
          const decimalIndex = strNum.indexOf('.');

          if (decimalIndex === -1) {
              return "00";  // 小数点がない場合は "00"
          }

          const decimalPart = strNum.substring(decimalIndex + digits);
          if (decimalPart.length < decimalIndex + digits) {
              // 小数点以下の桁数が足りない場合、ゼロで埋める
              return (decimalPart + "0000").substring(0, 1);
          }

          return decimalPart.substring(0, 1);
        }
        return ''
      },
      hidden(pair) {
        console.log('hidden')
        console.log(pair)
        this.hidden_pair.push(pair)
        this.getData()
      },
      reload(index, pair) {
        console.log('reload');
        console.log(this.charts[index]);
        axios.get(process.env.VUE_APP_API_URL + '/capchart/reload/' + pair)
        .then(response => {
          console.log(response.data); // mockData
          console.log(response.status); // 200
          this.charts[index].is_update = true;
        })
      },
      allreload() {
        console.log('allreload');
        axios.get(process.env.VUE_APP_API_URL + '/capchart/allreload')
        .then(response => {
          console.log(response.data); // mockData
          console.log(response.status); // 200
          this.charts.forEach(item => {
            item.is_update = true;
          })
        })
      },
      allopen() {
        this.charts.forEach(item => {
          window.open('imageview?pair=' + item.pair, '_blank')
        })
      }
    }
  }
</script>