import { createRouter, createWebHistory } from 'vue-router'
import CapPage from '@/page/CapPage.vue'
import AlertSettingPage from '@/page/AlertSettingPage.vue'
import ImageViewPage from '@/page/ImageViewPage.vue'

const routes = [
  {
    path: '/',
    name: 'top',
    component: CapPage
  },
  {
    path: '/cap',
    name: 'cap',
    component: CapPage
  },
  {
    path: '/alert',
    name: 'alert-param',
    component: AlertSettingPage,
    props: route => ({querypair: route.query.pair}),
  },
  {
    path: '/imageview',
    name: 'image-view',
    component: ImageViewPage,
    props: route => ({querypair: route.query.pair}),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router