<template>

  <v-carousel height="100%">
    <v-carousel-item 
      v-for="(item, index) in images"
      :key="item.chart_path"
      :src=item.chart_path>
      <v-btn v-if="index !== 0" @click="deleteImage(item.chart_path)">削除</v-btn>
    </v-carousel-item>

    <v-carousel-item
      :src="imageURL">
      <v-btn @click="updateImage" >保存</v-btn>
      <v-btn @click="captureScreenshot">スクリーンショットを取得</v-btn>
    </v-carousel-item>
  </v-carousel>

</template>

<script>
import axios from 'axios'

export default {
  name: 'App',
  data: () => ({
    select_pair: '',
    images: [],
    imageURL: "/capimage/black.png",
  }),
  created: function () {
    console.log(this.$route.query.pair)
    this.select_pair = this.$route.query.pair
    this.getData();
  },
  computed: {
  },
  components: {
  },
  methods: {
    // データ取得
    async getData() {
      axios.get(process.env.VUE_APP_API_URL + '/image/lists/' + this.select_pair)
      .then(response => {
        console.log(response.data); // mockData
        console.log(response.status); // 200
        this.images = response.data.images;
      })
    },
    async captureScreenshot() {
      try {
        const clipboardItems = await navigator.clipboard.read();
        for (const clipboardItem of clipboardItems) {
          if (clipboardItem.types.includes('image/png')) {
            const blob = await clipboardItem.getType('image/png');
            const reader = new FileReader();

            reader.onloadend = () => {
              const image_data_base64 = reader.result.split(',')[1];
              this.imageURL = 'data:image/png;base64,' + image_data_base64;
            }
            reader.readAsDataURL(blob);
          }
        }
      } catch (error) {
        console.error('クリップボードからの画像取得エラー', error);
      }
    },
    updateImage() {
      var image = {
        pair: this.select_pair,
        filename: this.select_pair + '_' + this.formatDateToString() + '.png',
        url: this.imageURL,
      }
      axios({
          method: "POST",
          url: process.env.VUE_APP_API_URL + '/image/add',
          data: JSON.stringify(image), 
          headers:{'Content-Type': 'application/json; charset=utf-8'}
      })
      .then(response => {
        console.log(response.data); // mockData
        console.log(response.status); // 200
        this.getData();
      })
    },
    deleteImage(path) {
      var image = {
        filepath: path,
      }
      axios({
          method: "POST",
          url: process.env.VUE_APP_API_URL + '/image/delete',
          data: JSON.stringify(image), 
          headers:{'Content-Type': 'application/json; charset=utf-8'}
      })
      .then(response => {
        console.log(response.data); // mockData
        console.log(response.status); // 200
        this.getData();
      })
    },
    formatDateToString() {
        const date = new Date();
        const year = date.getFullYear().toString();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月は0から始まるので1を足す
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return year + month + day + hours + minutes + seconds;
    }
  }
}
// document.addEventListener('paste', async (event) => {
//     const items = event.clipboardData.items;
//     for (const item of items) {
//         if (item.type.startsWith('image/')) {
//             const blob = item.getAsFile();
//             // ここで画像を扱う処理を行います
//         }
//     }
// });
</script>
  