<template>
  <v-row>
    <v-col>
    <v-card class="mx-auto"  max-width="90%">
      <v-form ref="store_form" @submit.prevent="saveChanges">
        <v-select
          v-model="select_pair"
          :items="pairs"
          label="選択ペア"
        ></v-select>
        <v-select
          v-model="select_time"
          :items="times"
          label="選択時間"
        ></v-select>
        <v-row>
          <v-col>
            <v-alert
              v-if="submitflag"
              type="success"
              title="アラートリクエスト完了"
            ></v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn type="submit" color="primary">アラート設定</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-col></v-row>
  <v-row>
    <v-col>
  <v-card class="mx-auto" max-width="90%">
    <v-list-item
      v-for="(item, index) in alerts"
      :key="item.id"
      :title="item.pair + ':' + item.time"
      :subtitle="item.update_time"
    >
      <template v-slot:append>
        <v-btn
          color="grey-lighten-1"
          icon="mdi-delete"
          variant="text"
          @click="deleteAlert(index)"
        ></v-btn>
      </template>
    </v-list-item>
  </v-card>
  </v-col>
</v-row>

</template>

<script>
  import axios from 'axios'

  export default {
    data () {
      return {
        pairs: ['USDJPY','EURUSD'],
        times: ['M5','M15','M30','H1','H4'],
        select_pair: '',
        select_time: 'H4',
        submitflag: false,
        alerts: [],
      }
    },
    created: function () {
      console.log(this.$route.query.pair)
      this.select_pair = this.$route.query.pair
      this.getData();
    },
    methods: {
      // データ取得
      async getData() {
        axios.get(process.env.VUE_APP_API_URL + '/capchart/lists')
        .then(response => {
          console.log(response.data); // mockData
          console.log(response.status); // 200
          var pairs = []
          response.data.charts.forEach((element) => {
            pairs.push(element.pair)
          })
          this.pairs = pairs;
        })
        axios.get(process.env.VUE_APP_API_URL + '/alertsetting/lists')
        .then(response => {
          console.log(response.data); // mockData
          console.log(response.status); // 200
          this.alerts = response.data.alerts;
        })
      },
      // 編集内容を保存
      async saveChanges() {
        axios.get(process.env.VUE_APP_API_URL + '/alertsetting/setting/' + this.select_pair + "/" + this.select_time)
        .then(response => {
          console.log(response.data); // mockData
          console.log(response.status); // 200
          this.submitflag = true
          this.getData();
        })
      },
      deleteAlert(index) {
        console.log('reload');
        console.log(this.alerts[index]);
        axios.get(process.env.VUE_APP_API_URL + '/alertsetting/delete/' + this.alerts[index].id)
        .then(response => {
          console.log(response.data); // mockData
          console.log(response.status); // 200
          this.getData();
        })
      },
    }
  }
</script>