<template>
    <router-view />
    <v-layout class="rounded rounded-md">
      <!-- TOPバー -->
      <v-app-bar color="indigo darken-3">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-app-bar-title>{{title}}</v-app-bar-title>
      </v-app-bar>
  
      <!-- メニュー -->
      <v-navigation-drawer v-model="drawer">
      <v-list>
          <v-list-item
          v-for="(item, i) in showitems"
          :key="i"
          :value="item.id"
          :to="item.link"
          color="primary">

          <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
          </template>

          <v-list-item-title v-text="item.text"></v-list-item-title>
          
          </v-list-item>
      </v-list>
      </v-navigation-drawer>

      <!-- メイン -->
      <v-main class="align-center justify-center" style="min-height: 300px;">
        <CapList/>
      </v-main>
  
      <!-- フッター -->
      <v-footer app color="primary">
      </v-footer>
    </v-layout>
  </template>
  
  <script>
  import CapList from '@/components/CapList.vue'
  
  export default {
    name: 'App',
    data: () => ({
      title: "Mt4 コントロール",
      items: [
        { id: 0, text: 'Chartリスト', icon: 'mdi-chart-line', link: '/cap', loginshow: false},
        { id: 1, text: 'アラート設定', icon: 'mdi-alarm-multiple', link: '/alert', loginshow: false},
      ],
      drawer: true,
      parentMessage: false,
    }),
    computed: {
      showitems() {
        if (this.$store.getters.getToken) {
          // ログイン中
          return this.items
        } else {
          // ログインしてない
          return this.items.filter(item => item.loginshow === false)
        }
        //console.log("message:" + message)
      }
    },
    components: {
        CapList,
    },
    methods: {
    }
  }
  </script>
  